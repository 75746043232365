import Amplify from '@aws-amplify/core'
import { Auth } from '@returnmates/client-core/src/amplify'
import StandingStore from '@returnmates/client-core/src/standingStore'
import { store } from '@returnmates/client-core/src/store/configureStore'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'

import App from './App'
import config from './config'

StandingStore.setStore({
  getItem: key => localStorage.getItem(key),
  setItem: (key, val: string) => localStorage.setItem(key, val),
  removeItem: key => localStorage.removeItem(key),
})

Amplify.configure({
  Auth: {
    identityPoolId: config.aws.identityPoolId,
    region: config.aws.region,
    userPoolId: config.aws.userPoolId,
    userPoolWebClientId: config.aws.userPoolWebClientId,
  },
})

const isHostEnv = (env: string): boolean => {
  return window.location.host.split('.')[0] === env || window.location.host.split('.')[1] === env
}

const getDomain = () => {
  let env

  if (isHostEnv('dev') || window.location.hostname.includes('localhost')) {
    env = 'dev'
  } else if (isHostEnv('stage')) {
    env = 'stage'
  } else {
    env = 'prod'
  }

  return `returnmates-${env}.auth.us-west-2.amazoncognito.com`
}

Auth.configure({
  Auth: {
    oauth: {
      domain: getDomain(),
      scope: ['email', 'profile', 'openid'],
      redirectSignIn: `${location.origin}/oauth`,
      redirectSignOut: `${location.origin}/`,
      responseType: 'code',
    },
    identityPoolId: config.aws.identityPoolId,
    region: config.aws.region,
    userPoolId: config.aws.userPoolId,
    userPoolWebClientId: config.aws.userPoolWebClientId,
  },
})

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
)
