import { ApolloProvider } from '@apollo/client'
import { ThemeProvider } from '@mui/material'
import StyledEngineProvider from '@mui/material/StyledEngineProvider'
import client from '@returnmates/client-core/src/graphql/callApi'
import { stripePromise } from '@returnmates/client-core/src/utils/stripe'
import { Elements } from '@stripe/react-stripe-js'
import { Stripe } from '@stripe/stripe-js'

import Root from './pages/Root'
import theme from './theme'

const options = {
  fonts: [
    {
      cssSrc: 'https://fonts.googleapis.com/css2?family=Poppins',
    },
  ],
}

const App = (): JSX.Element => {
  return (
    <div className="App">
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <ApolloProvider client={client.clientPublic}>
            <Elements stripe={stripePromise as Promise<Stripe>} options={options}>
              <Root />
            </Elements>
          </ApolloProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </div>
  )
}

export default App
