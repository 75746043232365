import { adaptV4Theme, createTheme } from '@mui/material/styles'
import {
  DARK_GRAY,
  GHOST_WHITE,
  GRAY,
  NEON_BLUE,
  NEON_BLUE_LIGHT,
  PERIWINKLE,
  SOLITUDE,
  VAMPIRE_BLACK,
} from '@returnmates/client-core/src/constants/colors'

import { POPPINS } from '../constants/styles/fonts'

const theme = createTheme(
  adaptV4Theme({
    overrides: {
      MuiButton: {
        root: {
          backgroundColor: NEON_BLUE_LIGHT,
          fontWeight: 600,
          fontFamily: POPPINS,
          lineHeight: '130%',
          fontSize: 16,
          color: '#FFFFFF',
          borderRadius: 5,
          '&:hover': {
            backgroundColor: NEON_BLUE,
            '@media (hover: none)': {
              backgroundColor: NEON_BLUE,
            },
          },
        },
        outlined: {
          border: 'none',
          padding: '16px 24px',
          '&:hover': {
            '&.Mui-disabled': {
              background: 'rgba(99, 93, 246, 0.4)',
            },
          },
          '&.Mui-disabled': {
            color: '#FFFFFF',
            border: 'none',
            background: 'rgba(99, 93, 246, 0.4)',
            cursor: 'not-allowed',
            pointerEvents: 'all !important',
          },
        },
      },
      MuiTextField: {
        root: {
          border: `1px solid ${PERIWINKLE}`,
          borderRadius: 4,
          fontSize: 12,
          padding: 0,
          fontWeight: 600,
          boxSizing: 'border-box',
          backgroundColor: '#FFFFFF',
          '& label': {
            backgroundColor: GHOST_WHITE,
            padding: '0 2px',
          },
          '&.Mui-focused': {
            border: `1px solid ${NEON_BLUE_LIGHT}`,
          },
          '&:hover': {
            border: `1px solid ${DARK_GRAY}`,
          },
        },
      },
      MuiBreadcrumbs: {
        root: {
          fontFamily: POPPINS,
          fontWeight: 400,
          fontSize: 14,
          lineHeight: '16px',
          '& a': {
            color: GRAY,
          },
          '& p': {
            color: GRAY,
          },
        },
      },
      MuiTable: {
        root: {
          background: '#FFFFFF',
        },
      },
      MuiTableCell: {
        head: {
          fontFamily: POPPINS,
          fontWeight: 400,
          fontSize: 14,
          lineHeight: '16px',
          color: GRAY,
        },
        root: {
          borderBottom: `1px solid ${SOLITUDE}`,
        },
      },
      MuiTableHead: {
        root: {
          background: '#F9FAFC',
          borderBottom: `1px solid ${SOLITUDE}`,
        },
      },
      MuiTableRow: {
        root: {
          borderBottom: `1px solid ${SOLITUDE}`,
        },
      },
      MuiSwitch: {
        root: {
          width: 46,
          height: 28,
          padding: 0,
          transition: 'all 0.5s',
          '&& > .Mui-checked': {
            transform: 'translateX(18px) ',
            color: '#fff',
            '& + .MuiSwitch-track': {
              backgroundColor: NEON_BLUE_LIGHT,
              opacity: 1,
            },
          },
        },
        switchBase: {
          padding: 0,
          margin: 2,
          transitionDuration: '300ms',
        },
        thumb: {
          boxSizing: 'border-box',
          width: 24,
          height: 24,
        },
        track: {
          borderRadius: 14,
          backgroundColor: '#E5E8ED',
          opacity: 1,
        },
      },
      MuiTab: {
        root: {
          marginRight: 44,
          padding: '0 0 13px',
          fontFamily: POPPINS,
          fontWeight: 400,
          fontSize: 16,
          color: VAMPIRE_BLACK,
          textTransform: 'initial',
          opacity: 1,
          '&&': {
            minWidth: 'unset',
          },
        },
        textColorInherit: {
          '&.Mui-selected': {
            color: NEON_BLUE_LIGHT,
          },
        },
      },
      // PrivateTabIndicator: {
      //   root: {},
      //   colorSecondary: {
      //     backgroundColor: NEON_BLUE_LIGHT,
      //   },
      // },
    },
    typography: {
      h1: {
        marginTop: 12,
        fontFamily: POPPINS,
        fontWeight: 600,
        fontSize: 32,
        lineHeight: '38px',
        color: VAMPIRE_BLACK,
      },
      h2: {
        marginTop: 10,
        fontFamily: POPPINS,
        fontWeight: 400,
        fontSize: 16,
        lineHeight: '19px',
        color: VAMPIRE_BLACK,
      },
    },
  }),
)

export default theme
