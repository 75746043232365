import * as actions from '@returnmates/client-core/src/constants/actionTypes'
import { getUserData } from '@returnmates/client-core/src/selectors/user'
import { createAsyncAction } from '@returnmates/client-core/src/utils/reduxUtils'
import refreshTimeout from '@returnmates/client-core/src/utils/refreshTimeout'
import SnackBars from '@returnmates/ui-core/src/components/SnackBars'
import Spinner from '@returnmates/ui-core/src/components/Spinner'
import AWS from 'aws-sdk'
import { lazy, memo, Suspense, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import config from '../../config'
import { FORGOT_PASSWORD, SHOPIFY_AUTH, UPDATE_PASSWORD } from '../../constants/paths'
import useStyles from './styles'

const AdminPanel = lazy(() => import('../AdminPanel'))
const ForgotPassword = lazy(() => import('../ForgotPassword'))
const Login = lazy(() => import('../Login'))
const ShopifyAuth = lazy(() => import('../ShopifyAuth'))
const UpdatePassword = lazy(() => import('../UpdatePassword'))

AWS.config.update({
  region: config.aws.region,
  credentials: new AWS.CognitoIdentityCredentials({
    IdentityPoolId: config.aws.identityPoolId,
  }),
})

function Root() {
  const [isLoading, setIsLoading] = useState(true)
  const [isZipCodeUploaded, setIsZipCodeUploaded] = useState(false)
  const classes = useStyles()
  const userData = useSelector(getUserData)
  const dispatch = useDispatch()

  const checkIsTokenExist = useCallback(async () => {
    try {
      await refreshTimeout(dispatch, true)
    } finally {
      setIsLoading(false)
    }
  }, [dispatch])

  const getServicedZipCodes = useCallback(async () => {
    try {
      await createAsyncAction(dispatch, actions.getServicedZipCodes.request({ where: {} }))
    } finally {
      setIsZipCodeUploaded(true)
    }
  }, [dispatch])

  useEffect(() => {
    checkIsTokenExist()
  }, [checkIsTokenExist])

  useEffect(() => {
    getServicedZipCodes()
  }, [getServicedZipCodes])

  if (isLoading || !isZipCodeUploaded) {
    return (
      <>
        <div className={classes.spinnerHandler}>
          <Spinner />
        </div>
        <SnackBars />
      </>
    )
  }

  if (!userData) {
    return (
      <>
        <Suspense fallback={<Spinner />}>
          <BrowserRouter>
            <Routes>
              <Route path={FORGOT_PASSWORD} element={<ForgotPassword />} />
              <Route path={UPDATE_PASSWORD} element={<UpdatePassword />} />
              <Route path="*" element={<Login />} />
            </Routes>
          </BrowserRouter>
        </Suspense>
        <SnackBars />
      </>
    )
  }

  return (
    <>
      <Suspense fallback={<Spinner />}>
        <BrowserRouter>
          <Routes>
            <Route path={SHOPIFY_AUTH} element={<ShopifyAuth />} />
            <Route path="*" element={<AdminPanel />} />
          </Routes>
        </BrowserRouter>
      </Suspense>
      <SnackBars />
    </>
  )
}

export default memo(Root)
